// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-associations-js": () => import("./../../../src/pages/associations.js" /* webpackChunkName: "component---src-pages-associations-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legalnotice-js": () => import("./../../../src/pages/legalnotice.js" /* webpackChunkName: "component---src-pages-legalnotice-js" */),
  "component---src-pages-volunteers-js": () => import("./../../../src/pages/volunteers.js" /* webpackChunkName: "component---src-pages-volunteers-js" */)
}

